






































































import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'
import FormGroup from '@/components/forms/FormGroup.vue'
import Field from '@/components/forms/Field.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import {
  OldPhotoInterface,
  StepFourInterface,
} from '@/store/modules/room.module'



const MAX_PICTURE_SIZE = 1048576 * 5 // 5 MB
const MAX_TOTAL_PICTURE_SIZE = 1048576 * 32 // 32 MB

@Component({
  name: 'StepFour',
  components: { BaseButton, BaseIcon, vueCustomScrollbar, FormGroup, Field},
})
export default class StepFour extends Vue {
  @Prop({ required: true })
  value!: StepFourInterface

  isMobile!: boolean

  

  @Watch('value')
  onValueChanged(val: StepFourInterface): void {
    this.updateModel(val)
  }


  youtubeLink = ''
  images: File[] = []
  imagesPreviews: string[] = []
  oldPhotos: OldPhotoInterface[] = []
  limit = 15

  error = ''

  @Ref('inputFile') readonly file!: HTMLInputElement

  openWindow(): void {
    const element: HTMLElement = this.$refs.inputFile as HTMLElement
    if (element) {
      element.click()
    }
  }

  @Emit('change-images')
  change(event: Event): File[] {
    this.error = ''
    const element = event.target as HTMLInputElement
    const files = [...(element.files as FileList)]

    if (files.length) {
      if (
        this.oldVisiblePhotos.length + this.images.length + files.length >
        this.limit
      ) {
        this.error = `Максимальное допустимое кол-во фотографий - ${this.limit}`
      } else if (!files.every(this.isLoadingFileValid)) {
        this.error =
          'Файл имеет недопустимый формат или размер фотографии превышает 5мб'
      } else if (
        files.reduce((acc, file) => acc + file.size, 0) +
          this.totalPicturesSize >
        MAX_TOTAL_PICTURE_SIZE
      ) {
        this.error = 'Общий размер файлов должен быть меньше 32мб'
      } else {
        files.forEach((file) => {
          const url = URL.createObjectURL(file)
          this.images.push(Object.assign(file, {}))
          this.imagesPreviews.push(url)
        })
        this.update()
      }
    }
    element.value = ''

    return this.images
  }

  deleteImage(idx: number): void {
    this.images.splice(idx, 1)
    this.imagesPreviews.splice(idx, 1)
  }

  deleteOldImage(uid: number): void {
    const oldPhoto = this.value.oldPhotos.find((photo) => photo.uid === uid)
    if (oldPhoto) {
      oldPhoto.status = 'removed'
    }
  }

  public isLoadingFileValid(file: File): boolean {
    return file.type === 'image/jpeg' && file.size < MAX_PICTURE_SIZE
  }

  public checkValidity(): boolean {
    if (
      !this.images.length &&
      !this.oldVisiblePhotos.length &&
      this.error === ''
    ) {
      this.error = 'Добавьте фото'
    }

    return (
      ((this.images && this.images.length > 0) ||
        this.oldVisiblePhotos.length > 0) &&
      this.totalPicturesSize < MAX_TOTAL_PICTURE_SIZE
    )
  }

  public updateModel(data: StepFourInterface): void {
    this.youtubeLink = data.youtubeLink 
    this.oldPhotos = data.oldPhotos as OldPhotoInterface[]
    this.images = data.photos as File[]

    if (this.images.length !== this.imagesPreviews.length) {
      this.imagesPreviews = []
      for (let key in this.images) {
        this.imagesPreviews.push(URL.createObjectURL(this.images[key]))
      }
    }
  }

  get totalPicturesSize(): number {
    return this.images?.reduce((acc, img) => acc + img.size, 0)
  }

  get oldVisiblePhotos(): OldPhotoInterface[] {
    return this.oldPhotos.filter((item) => item.status !== 'removed')
  }

  public update(): void {
    this.$emit('input', {
      youtubeLink: this.youtubeLink,
      photos: this.images,
      oldPhotos: this.oldPhotos,
    })
  }

  public mounted(): void {
    this.updateModel(this.value)
  }
}
